// body,
// html,
// div.printable {
//     height: 100% !important;
//     width: 100% !important;
//     display: inline-block;
//     overflow: visible !important;
// }

body:has(div.printable),
html:has(div.printable) {
    height: 100% !important;
    width: 100% !important;
    overflow: visible !important;
    background-color: white;
}

div.printable {
    background-color: white;

    div.document-header {
        font-size: 12pt;
        font-weight: bold;
        border-bottom: 2px solid #ff9933;
        margin-bottom: 25px;
        margin-top: 10px;
    }

    .scheme-investor-data-table {
        display: flex;
        width: 100%;
        flex-direction: column;
        font-family: Calibri, sans-serif !important;
        margin-bottom: 10pt;

        div.top-notes {
            display: flex;
            flex-direction: column;

            >div {

                font-style: italic;
                text-align: right;
                font-size: 8pt;
            }
        }

        div.bottom-notes {
            display: flex;
            flex-direction: column;
            margin-top: 10pt;
            margin-bottom: 10pt;

            >div {
                font-style: italic;
                font-size: 8pt;
            }
        }

        >table {
            border-collapse: collapse;

            tr {
                td {
                    color: black;
                    padding-top: 1pt;
                    padding-left: 2pt;
                    padding-right: 5pt;
                    border-bottom: 0.5pt #BEBEBE solid;
                    font-size: 8pt;
                }
            }

            tr.header-row-0 {
                th {
                    padding: 10px;
                    background-color: rgb(29, 27, 27);
                    color: white;
                    font-size: 10pt;
                    font-weight: bold;
                    border-top: 3px solid #ff9933;
                    margin-bottom: 40px;
                    white-space: nowrap;
                    text-align: left;
                }
            }

            tr.header-row-1,
            tr.header-row-2 {
                th {
                    padding: 10px;
                    background-color: #f2f2f2;
                    color: black;
                    font-size: 8pt;
                    font-weight: bold;
                    border-bottom: 1pt #BEBEBE solid;
                    white-space: nowrap;
                    text-align: left;
                }
            }

            tr.footer-row {
                &-0 td {
                    background-color: #F1F1F1;
                    font-weight: bold;
                    border-bottom: 1pt #BEBEBE solid;
                    text-align: right;
                }

                &-1 td {
                    background-color: #D9D9D9;
                    font-weight: bold;
                    border-top: 1pt #BEBEBE solid;
                    border-bottom: 1pt #BEBEBE solid;
                    text-align: right;
                }
            }
        }

    }

    // p {
    //     margin-bottom: 12px;
    //     line-height: 20px;
    //     margin-block-end: 12px;
    // }

    .notes {
        margin-top: 20pt;
        color: black;
        font-style: italic;
        font-family: Calibri, sans-serif !important;
        font-size: 9pt;

        &-header {
            color: black;
            font-weight: bold;
            text-decoration: underline;
            margin-bottom: 5px;
            font-family: Calibri, sans-serif !important;
            font-size: 9pt;
        }
    }

    .notice {
        margin-top: 20px;
        border: 0.2pt solid #7E7E7E;
        padding: 20px;
        margin-left: 5px;
        margin-right: 5px;
        font-size: 9pt;
        font-family: Calibri, sans-serif !important;
        margin-bottom: 10px;
    }
}

* {
    -webkit-print-color-adjust: exact !important;
    /* Chrome, Safari 6 – 15.3, Edge */
    color-adjust: exact !important;
    /* Firefox 48 – 96 */
    print-color-adjust: exact !important;
    /* Firefox 97+, Safari 15.4+ */
}